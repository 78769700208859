<template>
  <div>
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" readonly label="姓名" />
        <van-field v-model="form.stuNo" readonly label="学号" />
        <van-field v-model="form.gradeNo" readonly label="年级" />
        <van-field v-model="form.unitName" readonly label="院系" />
        <van-field v-model="form.specName" readonly label="专业" />
        <van-field v-model="form.className" readonly label="班级" />
        <van-field v-model="form.cardNo" readonly label="证件号码" />
        <van-field v-model="form.description" rows="3" class="textarea-style" :required="true" :rules="[{ required: true } ]" placeholder="请填写补办说明" label="申请说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <!-- 其他信息 -->
      <van-cell-group title="登报证明" class="upload-file" style="margin-bottom:65px;">
        <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" :max-size="1000 * 1024" @oversize="onOversize" />
      </van-cell-group>
      <div class="submit-button  shadow-style">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit">提 交</van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getTermYear } from '@/utils/index'
import { mapGetters } from 'vuex'
import fileUpload from "@/components/FileUpload";
import crudStuCardReplacement from '@/api/modules/daily/stuCardReplacement'
import { getStuinfo } from '@/api/modules/xj/stuInfo'

export default {
  name: "StuPayReport",
  components: { fileUpload },
  dicts: ['xj_nj', 'xj_xqgl'],
  data() {
    return {
      form: {
        id: null,
        description: null,
        file: null,
        filename: null,
        unitName: null,
        specName: null,
        gradeNo: null,
        className: null,
        cardNo: null,
      },
      fileList: [],
      stuInfo: {},
      statusDict: [
        { label: "已缴纳", value: true },
        { label: "未缴纳", value: false },
      ],
      loading: false,
      refsh: true
    }
  },
  watch: {
    "dict.xj_xqgl": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0 && !this.$route.query.id) {
          this.schYearDefault();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.$nextTick((e) => {
      if (this.$route.query.id) {
        crudStuCardReplacement.getById(this.$route.query.id).then((res) => {
          this.form = { ...res }
          this.form.file = res.attachment
          let img = crudStuCardReplacement.dbImgView(this.$route.query.id)
          this.fileList = [{
            url: img, isImage: true
          }]
        })
      } else {
        getStuinfo({ stuNo: this.user.username }).then((res) => {
          this.form.stuNo = this.user.username
          this.form.stuName = this.user.nickName
          this.form.unitName = res.collName
          this.form.specName = res.sqecName
          this.form.gradeNo = res.gradeNo
          this.form.className = res.className
          this.form.cardNo = res.cardNo
        })
      }
    })
  },
  methods: {
    schYearDefault() {
      let termYear = getTermYear(
        this.dict.xj_xqgl,
        (new Date().getMonth() + 1).toString()
      )
      this.form.schYear = termYear
    },
    onOversize(file) {
      this.$notify({
        type: "warning",
        message: "文件大小不能超过 1MB",
        duration: 1500,
      });
    },
    afterRead(file) {
      this.form.filename = file.file.name
      this.form.file = file.file
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (!this.form.file) {
            this.$notify({
              type: "warning",
              message: "请上传附件证明！",
              duration: 1500,
            });
            return
          }
          // 验证通过数据提交
          this.$dialog
            .confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
            .then(() => {
              this.loading = true;

              if (this.$route.query && this.$route.query.id) {
                this.doEdit();
              } else {
                this.doAdd();
              }
            })
            .catch(() => {
              // on cancel
            });
        },
        (err) => {
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    cancel() {
      this.loading = false
      this.form = {
        id: null,
        description: null,
        file: null,
        filename: null,
        unitName: null,
        specName: null,
        gradeNo: null,
        className: null,
        cardNo: null,
      }
      this.$router.go(-1)
    },
    doEdit() {
      crudStuCardReplacement.edit(this.form).then((res) => {
        this.$notify({
          type: "success",
          message: "操作成功",
          duration: 2000,
        });
        this.cancel()
      })
    },
    doAdd() {
      crudStuCardReplacement.add(this.form).then((res) => {
        this.$notify({
          type: "success",
          message: "操作成功",
          duration: 2000,
        });
        this.cancel()
      })
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error('blobToBase64 error'));
        };
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.radio-other-info {
  .van-cell {
    .van-field__label {
      width: 11.2em;
    }
  }
}

.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.textarea-style {
  height: 100%;
}
</style>